import React from 'react';
import { motion } from 'framer-motion';

const PurposeOfWebsitePage = () => {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Purpose of This Website
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden mb-10"
        >
          <div className="p-8 sm:p-12">
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                The IAAII website – <span className="text-[#F47B20] font-medium">http://www.iaai-global.org</span> – is a platform that provides valuable information for IAAII members, artificial intelligence and informatics professionals, researchers, and other interested individuals to support the mission of the IAAII as an international, non-profit organization established by decree of Wisconsin College in 2016.
              </p>
              <p>
                The primary purpose of this website is to provide information about the IAAII, its member organizations, and their activities that are consistent with the IAAII's mission, vision, and goals. In addition, the website provides resources related to artificial intelligence and informatics and connects users to relevant external sources of information in these areas.
              </p>
              <p>
                The primary audience for this website includes IAAII institutional members, individual AI and informatics professionals, researchers, students, developers, vendors, and consultants who share a common interest in advancing AI and informatics. The content is curated to raise awareness, foster collaboration and promote knowledge sharing in these fields.
              </p>
              <p>
                The IAAII endeavors to present accurate information, but makes no guarantee or legal liability for the accuracy, completeness, or usefulness of the information, products, or services published on this website. We make every effort to ensure that the information provided is up-to-date and accurate. If you notice any errors or outdated content, please let us know. References to specific commercial products, services or companies do not imply endorsement by IAAII.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden mb-10"
        >
          <div className="p-8 sm:p-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">What This Website Does Not Do</h2>
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
              <p className="text-gray-700">
                This website does <span className="font-bold">NOT</span> provide specific technical, legal, financial or health-related advice. The content is for information purposes only.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden mb-10"
        >
          <div className="p-8 sm:p-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Who Maintains This Site</h2>
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                The IAAII website is maintained by the IAAII Office/Secretariat. The designated contact person for all website-related inquiries is the IAAII Executive Director. Website content is managed by IAAII staff or as directed by the Secretariat. Information about IAAII member organizations and individuals is provided by the respective entities, who are responsible for ensuring its accuracy and timely updates.
              </p>
              <p>
                The website is regularly monitored, with the goal of responding to inquiries within 24 hours, and often much sooner.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-8 sm:p-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Links to Other Sites</h2>
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                This website contains links to external websites for the convenience of our users. IAAII does not endorse, approve, or control these external sites and is not responsible for the accuracy, completeness, or reliability of information found there. Users should exercise caution when accessing external content.
              </p>
            </div>
          </div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="max-w-4xl mx-auto mt-12"
        >
          <div className="bg-[#182C49]/5 p-6 rounded-lg text-center">
            <p className="text-gray-600">
              If you have any questions about this website, please contact us at <span className="text-[#F47B20] font-medium">webmaster@iaaii.org</span>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PurposeOfWebsitePage; 