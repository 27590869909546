import React from 'react';
import { motion } from 'framer-motion';

const IssuesPage = () => {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Journal Issues
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Explore the latest publications and archives of the International Journal of AI and Informatics
          </p>
        </motion.div>
        
        <div className="max-w-5xl mx-auto">
          {/* Current Issue Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg overflow-hidden mb-12"
          >
            <div className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Current Issue</h2>
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Featured Articles:</h3>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-5 rounded-lg">
                  <h4 className="text-lg font-medium text-[#182C49]">Article Title Goes Here</h4>
                  <p className="text-sm text-gray-500 mt-1">Authors: Author Name, Co-Author Name</p>
                  <p className="text-gray-600 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Vivamus hendrerit arcu sed erat molestie vehicula.</p>
                  <div className="mt-3">
                    <a href="#" className="inline-flex items-center text-sm font-medium text-[#F47B20] hover:text-[#d86817]">
                      Download PDF
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                    </a>
                  </div>
                </div>
                
                <div className="bg-gray-50 p-5 rounded-lg">
                  <h4 className="text-lg font-medium text-[#182C49]">Second Article Title Goes Here</h4>
                  <p className="text-sm text-gray-500 mt-1">Authors: Author Name, Co-Author Name</p>
                  <p className="text-gray-600 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Vivamus hendrerit arcu sed erat molestie vehicula.</p>
                  <div className="mt-3">
                    <a href="#" className="inline-flex items-center text-sm font-medium text-[#F47B20] hover:text-[#d86817]">
                      Download PDF
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          
          {/* Browse Archives Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg overflow-hidden mb-12"
          >
            <div className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Browse Archives</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {["2025", "2024", "2023"].map((year) => (
                  <motion.div
                    key={year}
                    whileHover={{ scale: 1.03 }}
                    className="bg-gray-50 p-4 rounded-lg text-center"
                  >
                    <h3 className="text-lg font-medium text-gray-900">{year}</h3>
                    <p className="text-sm text-gray-500 mb-3">Volume {parseInt(year) - 2020}</p>
                    <button className="inline-flex items-center text-sm font-medium text-[#182C49]">
                      View Issues
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
          
          {/* Special Issues Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="bg-white rounded-xl shadow-lg overflow-hidden"
          >
            <div className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Call for Papers: Special Issues</h2>
              
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Upcoming Themes:</h3>
              <div className="space-y-6 mb-8">
                <div className="bg-[#182C49]/5 p-5 rounded-lg border-l-4 border-[#182C49]">
                  <h4 className="text-lg font-medium text-[#182C49]">AI for Sustainable Development</h4>
                  <p className="text-gray-600 mt-2">Exploring how AI technologies can contribute to achieving the UN Sustainable Development Goals.</p>
                  <p className="text-sm font-semibold text-[#F47B20] mt-3">Submission Deadline: December 1, 2025</p>
                </div>
                
                <div className="bg-[#182C49]/5 p-5 rounded-lg border-l-4 border-[#182C49]">
                  <h4 className="text-lg font-medium text-[#182C49]">Ethics in AI and Informatics</h4>
                  <p className="text-gray-600 mt-2">Examining ethical considerations, frameworks, and practices in the development and deployment of AI systems.</p>
                  <p className="text-sm font-semibold text-[#F47B20] mt-3">Submission Deadline: December 1, 2025</p>
                </div>
              </div>
              
              <h3 className="text-xl font-semibold text-gray-800 mb-4">How to Propose a Special Issue:</h3>
              <div className="bg-gray-50 p-5 rounded-lg">
                <p className="text-gray-600">Submit a proposal to <span className="text-[#182C49] font-medium">[email]</span> with a theme description, guest editors, and timeline.</p>
                <div className="mt-6">
                  <button className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#182C49] hover:bg-[#0f1c30] transition duration-150">
                    Download Proposal Template
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default IssuesPage; 