import React from 'react';
import { motion } from 'framer-motion';

const SubmissionGuidelinesPage = () => {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Submission Guidelines
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Information for authors interested in submitting manuscripts to the Journal of AI and Informatics
          </p>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <div className="prose prose-lg max-w-none text-gray-600">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Types of Submissions</h2>
                <ul className="list-disc pl-6 mb-6">
                  <li>Research Articles</li>
                  <li>Review Papers</li>
                  <li>Short Communications</li>
                  <li>Case Studies</li>
                  <li>Special Issue Proposals</li>
                </ul>
                
                <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Submission Process</h2>
                <ol className="list-decimal pl-6 mb-6">
                  <li>Prepare your manuscript according to the Author Guidelines (<a href="#" className="text-[#F47B20] hover:text-[#d86817]">link to PDF</a>).</li>
                  <li>Submit via the Online Submission Portal (<a href="#" className="text-[#F47B20] hover:text-[#d86817]">link</a>).</li>
                  <li>Track your submission status through the portal.</li>
                </ol>
                
                <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Author Guidelines</h2>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">1. General Information</h3>
                <ul className="mb-4">
                  <li><strong>Submission Types:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Research Articles</li>
                      <li>Review Papers</li>
                      <li>Short Communications</li>
                      <li>Case Studies</li>
                      <li>Special Issue Contributions</li>
                    </ul>
                  </li>
                  <li><strong>Language:</strong> All submissions must be in English.</li>
                  <li><strong>Originality:</strong> Manuscripts must be original and not under consideration elsewhere.</li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">2. Formatting Requirements</h3>
                <p><strong>Document Format:</strong></p>
                <ul className="list-disc pl-6 mb-4">
                  <li>File Type: Microsoft Word (.docx) or LaTeX (PDF for initial submission).</li>
                  <li>Font: Times New Roman, 12-point.</li>
                  <li>Line Spacing: Double-spaced.</li>
                  <li>Margins: 1 inch (2.54 cm) on all sides.</li>
                </ul>
                
                <p><strong>Structure:</strong></p>
                <ol className="list-decimal pl-6 mb-4">
                  <li><strong>Title Page:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Title of the manuscript (concise and descriptive).</li>
                      <li>Full names, affiliations, and email addresses of all authors.</li>
                      <li>Corresponding author clearly marked.</li>
                    </ul>
                  </li>
                  <li><strong>Abstract:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Maximum 250 words.</li>
                      <li>Summarize the purpose, methods, results, and conclusions.</li>
                    </ul>
                  </li>
                  <li><strong>Keywords:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Provide 5–7 keywords relevant to the manuscript.</li>
                    </ul>
                  </li>
                  <li><strong>Main Text:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Research Articles:
                        <ul className="list-disc pl-6">
                          <li>Introduction</li>
                          <li>Methods</li>
                          <li>Results</li>
                          <li>Discussion</li>
                          <li>Conclusion</li>
                        </ul>
                      </li>
                      <li>Review Papers:
                        <ul className="list-disc pl-6">
                          <li>Introduction</li>
                          <li>Literature Review</li>
                          <li>Discussion</li>
                          <li>Conclusion and Future Directions</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li><strong>References:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Follow APA 7th edition style (see examples below).</li>
                    </ul>
                  </li>
                  <li><strong>Appendices (if applicable):</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Include supplementary materials such as datasets, code, or detailed proofs.</li>
                    </ul>
                  </li>
                </ol>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">3. Word Limits</h3>
                <ul className="list-disc pl-6 mb-4">
                  <li>Research Articles: Maximum 6,000 words (excluding references and appendices).</li>
                  <li>Review Papers: Maximum 8,000 words.</li>
                  <li>Short Communications: Maximum 3,000 words.</li>
                  <li>Case Studies: Maximum 5,000 words.</li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">4. Figures and Tables</h3>
                <p><strong>Figures:</strong></p>
                <ul className="list-disc pl-6 mb-4">
                  <li>Format: High-resolution (300 dpi) in TIFF, JPEG, or PNG.</li>
                  <li>Captions: Include a brief description below each figure.</li>
                  <li>Placement: Indicate where figures should be placed in the text (e.g., "Insert Figure 1 here").</li>
                </ul>
                
                <p><strong>Tables:</strong></p>
                <ul className="list-disc pl-6 mb-4">
                  <li>Format: Use Word's table tool or Excel.</li>
                  <li>Captions: Include a brief description above each table.</li>
                  <li>Placement: Indicate where tables should be placed in the text (e.g., "Insert Table 1 here").</li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">5. Citation Style</h3>
                <p><strong>APA 7th Edition Guidelines:</strong></p>
                <ul className="mb-4">
                  <li><strong>In-Text Citations:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Single author: (Smith, 2020)</li>
                      <li>Two authors: (Smith & Jones, 2020)</li>
                      <li>Three or more authors: (Smith et al., 2020)</li>
                    </ul>
                  </li>
                  <li><strong>Reference List:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Journal Article:<br />
                      Smith, J., & Jones, M. (2020). Title of the article. Journal Name, Volume(Issue), Page range. https://doi.org/xxxx</li>
                      <li>Book:<br />
                      Smith, J. (2020). Title of the book. Publisher.</li>
                      <li>Conference Paper:<br />
                      Smith, J., & Jones, M. (2020). Title of the paper. In Proceedings of the Conference Name (pp. xxx-xxx). Publisher.</li>
                      <li>Website:<br />
                      Smith, J. (2020). Title of the webpage. Website Name. https://www.example.com</li>
                    </ul>
                  </li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">6. Ethical Guidelines</h3>
                <ul className="mb-4">
                  <li><strong>Plagiarism:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>All submissions will be screened for plagiarism using Turnitin or similar software.</li>
                      <li>Manuscripts with significant overlap with other works will be rejected.</li>
                    </ul>
                  </li>
                  <li><strong>Data Integrity:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Authors must ensure the accuracy and reproducibility of their data.</li>
                      <li>Provide access to datasets and code where applicable.</li>
                    </ul>
                  </li>
                  <li><strong>Authorship:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>All authors must have made significant contributions to the work.</li>
                      <li>Include a statement of authorship contributions in the manuscript.</li>
                    </ul>
                  </li>
                  <li><strong>Conflicts of Interest:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>Disclose any financial or personal relationships that could influence the research.</li>
                    </ul>
                  </li>
                  <li><strong>Human and Animal Studies:</strong>
                    <ul className="list-disc pl-6 mb-2">
                      <li>For studies involving human or animal subjects, include ethical approval details and informed consent statements.</li>
                    </ul>
                  </li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">7. Submission Checklist</h3>
                <p>Before submitting, ensure your manuscript includes:</p>
                <ul className="list-disc pl-6 mb-4">
                  <li>A title page with author details.</li>
                  <li>An abstract and keywords.</li>
                  <li>Properly formatted main text, figures, and tables.</li>
                  <li>A reference list in APA style.</li>
                  <li>A statement of ethical compliance (if applicable).</li>
                </ul>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">8. Submission Process</h3>
                <ol className="list-decimal pl-6 mb-4">
                  <li>Register on the IJAAI Submission Portal (<a href="#" className="text-[#F47B20] hover:text-[#d86817]">link</a>).</li>
                  <li>Upload your manuscript and supplementary files.</li>
                  <li>Complete the submission form and agree to the journal's policies.</li>
                  <li>Track your submission status through the portal.</li>
                </ol>
                
                <div className="border-t border-gray-200 my-6"></div>
                
                <h3 className="text-xl font-bold text-gray-900 mt-6 mb-3">9. Contact for Support</h3>
                <p>For questions about submissions, contact:</p>
                <ul className="list-disc pl-6 mb-4">
                  <li>Editorial Office: [                             ]</li>
                  <li>Technical Support: [                        ]</li>
                </ul>
                
                <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Peer Review Process</h2>
                <ul className="list-disc pl-6 mb-6">
                  <li>Double-blind peer review.</li>
                  <li>Average review time: two weeks.</li>
                </ul>
                
                <div className="bg-[#182C49]/5 p-6 rounded-lg text-center mt-8">
                  <h3 className="text-xl font-bold text-[#182C49] mb-4">Ready to Submit?</h3>
                  <a 
                    href="#" 
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#182C49] hover:bg-[#0f1c30] transition duration-150"
                  >
                    Visit Our Submission Portal
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SubmissionGuidelinesPage; 