import React, { useState } from 'react';
import { motion } from 'framer-motion';

const RegionsPage = () => {
  const [activeRegion, setActiveRegion] = useState(null);

  const regions = [
    {
      id: "na",
      name: "North America",
      code: "IAAII-NA",
      description: "Representing the United States, Canada, and Mexico",
      countries: [
        { name: "United States", code: "IAAII-NA-US" },
        { name: "Canada", code: "IAAII-NA-CA" },
        { name: "Mexico", code: "IAAII-NA-MX" }
      ],
      representative: "Facilitates collaboration among North American members, organizes regional AI summits, and represents the region at global IAAII meetings.",
      color: "#3b82f6",
      icon: "🌎"
    },
    {
      id: "lac",
      name: "Latin America & the Caribbean",
      code: "IAAII-LAC",
      description: "Representing countries across Latin America and the Caribbean",
      countries: [
        { name: "Brazil", code: "IAAII-LAC-BR" },
        { name: "Argentina", code: "IAAII-LAC-AR" },
        { name: "Chile", code: "IAAII-LAC-CL" },
        { name: "Colombia", code: "IAAII-LAC-CO" },
        { name: "Other countries in the region", code: "" }
      ],
      representative: "Promotes AI education and innovation, coordinates regional workshops, and advocates for Latin American interests in global AI policy.",
      color: "#10b981",
      icon: "🌴"
    },
    {
      id: "eu",
      name: "Europe",
      code: "IAAII-EU",
      description: "Representing countries across Western, Eastern, Northern, and Southern Europe",
      subregions: [
        {
          name: "Western Europe",
          code: "IAAII-EU-WE",
          countries: [
            { name: "Germany", code: "IAAII-EU-DE" }, 
            { name: "France", code: "IAAII-EU-FR" }, 
            { name: "UK", code: "IAAII-EU-GB" }, 
            { name: "Netherlands", code: "IAAII-EU-NL" }
          ]
        },
        {
          name: "Eastern Europe",
          code: "IAAII-EU-EE",
          countries: [
            { name: "Poland", code: "IAAII-EU-PL" }, 
            { name: "Hungary", code: "IAAII-EU-HU" }, 
            { name: "Czech Republic", code: "IAAII-EU-CZ" }
          ]
        },
        {
          name: "Northern Europe",
          code: "IAAII-EU-NE",
          countries: [
            { name: "Sweden", code: "IAAII-EU-SE" }, 
            { name: "Finland", code: "IAAII-EU-FI" }, 
            { name: "Norway", code: "IAAII-EU-NO" }, 
            { name: "Denmark", code: "IAAII-EU-DK" }
          ]
        },
        {
          name: "Southern Europe",
          code: "IAAII-EU-SE",
          countries: [
            { name: "Italy", code: "IAAII-EU-IT" }, 
            { name: "Spain", code: "IAAII-EU-ES" }, 
            { name: "Portugal", code: "IAAII-EU-PT" }, 
            { name: "Greece", code: "IAAII-EU-GR" }
          ]
        }
      ],
      representative: "Coordinates cross-border AI research initiatives, organizes the European AI Forum, and represents European members globally.",
      color: "#f59e0b",
      icon: "🏰"
    },
    {
      id: "af",
      name: "Africa",
      code: "IAAII-AF",
      description: "Representing countries across Northern and Sub-Saharan Africa",
      subregions: [
        {
          name: "Northern Africa",
          code: "IAAII-AF-NA",
          countries: [
            { name: "Egypt", code: "IAAII-AF-EG" }, 
            { name: "Morocco", code: "IAAII-AF-MA" }, 
            { name: "Algeria", code: "IAAII-AF-DZ" }
          ]
        },
        {
          name: "Sub-Saharan Africa",
          code: "IAAII-AF-SSA",
          countries: [
            { name: "South Africa", code: "IAAII-AF-ZA" }, 
            { name: "Nigeria", code: "IAAII-AF-NG" }, 
            { name: "Kenya", code: "IAAII-AF-KE" }, 
            { name: "Ghana", code: "IAAII-AF-GH" }
          ]
        }
      ],
      representative: "Drives AI adoption in healthcare and agriculture, organizes regional training programs, and advocates for African AI innovation.",
      color: "#ef4444",
      icon: "🦁"
    },
    {
      id: "me",
      name: "Middle East",
      code: "IAAII-ME",
      description: "Representing countries in the Gulf Region and Levant",
      subregions: [
        {
          name: "Gulf Region",
          code: "IAAII-ME-GULF",
          countries: [
            { name: "UAE", code: "IAAII-ME-AE" }, 
            { name: "Saudi Arabia", code: "IAAII-ME-SA" }, 
            { name: "Qatar", code: "IAAII-ME-QA" }, 
            { name: "Kuwait", code: "IAAII-ME-KW" }
          ]
        },
        {
          name: "Levant",
          code: "IAAII-ME-LEVANT",
          countries: [
            { name: "Jordan", code: "IAAII-ME-JO" }, 
            { name: "Lebanon", code: "IAAII-ME-LB" }
          ]
        }
      ],
      additionalCountries: [
        { name: "Other countries in the region", code: "" }
      ],
      representative: "Focuses on AI in smart cities and energy, organizes Gulf AI Conferences, and represents Middle Eastern interests globally.",
      color: "#8b5cf6",
      icon: "🕌"
    },
    {
      id: "apac",
      name: "Asia-Pacific",
      code: "IAAII-APAC",
      description: "Representing countries across East Asia, South Asia, Southeast Asia, and Oceania",
      subregions: [
        {
          name: "East Asia",
          code: "IAAII-APAC-EA",
          countries: [
            { name: "China", code: "IAAII-APAC-CN" }, 
            { name: "Japan", code: "IAAII-APAC-JP" }, 
            { name: "South Korea", code: "IAAII-APAC-KR" }
          ]
        },
        {
          name: "South Asia",
          code: "IAAII-APAC-SA",
          countries: [
            { name: "India", code: "IAAII-APAC-IN" }, 
            { name: "Pakistan", code: "IAAII-APAC-PK" }, 
            { name: "Bangladesh", code: "IAAII-APAC-BD" }, 
            { name: "Sri Lanka", code: "IAAII-APAC-LK" }
          ]
        },
        {
          name: "Southeast Asia",
          code: "IAAII-APAC-SEA",
          countries: [
            { name: "Indonesia", code: "IAAII-APAC-ID" }, 
            { name: "Thailand", code: "IAAII-APAC-TH" }, 
            { name: "Vietnam", code: "IAAII-APAC-VN" }, 
            { name: "Philippines", code: "IAAII-APAC-PH" }
          ]
        },
        {
          name: "Oceania",
          code: "IAAII-APAC-OCE",
          countries: [
            { name: "Australia", code: "IAAII-APAC-AU" }, 
            { name: "New Zealand", code: "IAAII-APAC-NZ" }, 
            { name: "Fiji", code: "IAAII-APAC-FJ" }
          ]
        }
      ],
      representative: "Promotes AI in manufacturing and education, organizes the Asia-Pacific AI Expo, and represents the region's diverse AI ecosystem.",
      color: "#ec4899",
      icon: "🏯"
    },
    {
      id: "ca",
      name: "Central Asia",
      code: "IAAII-CA",
      description: "Representing Kazakhstan, Uzbekistan, Turkmenistan, Kyrgyzstan, and Tajikistan",
      countries: [
        { name: "Kazakhstan", code: "IAAII-CA-KZ" },
        { name: "Uzbekistan", code: "IAAII-CA-UZ" },
        { name: "Turkmenistan", code: "IAAII-CA-TM" },
        { name: "Kyrgyzstan", code: "IAAII-CA-KG" },
        { name: "Tajikistan", code: "IAAII-CA-TJ" }
      ],
      representative: "Focuses on AI in natural resource management, organizes Central AI Forums, and advocates for regional AI development.",
      color: "#0ea5e9",
      icon: "🏔️"
    }
  ];

  const responsibilities = [
    "Facilitate collaboration among members in their region.",
    "Organize regional events (e.g., conferences, workshops, training programs).",
    "Represent local interests and priorities at global IAAII meetings.",
    "Promote IAAII membership and initiatives within their region."
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            IAAII Represented Regions
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Our global network spans seven major regions, each with dedicated representation and local initiatives.
          </p>
        </motion.div>

        {/* World Map Visualization */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          className="mb-16 relative"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-3/4 p-6 relative aspect-video bg-gray-100 flex items-center justify-center">
                <div className="text-center">
                  <svg className="w-full h-auto" viewBox="0 0 1200 600" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1200" height="600" fill="#f8fafc" />
                    <text x="600" y="300" fontFamily="Arial" fontSize="24" fill="#64748b" textAnchor="middle">Interactive World Map</text>
                    <text x="600" y="340" fontFamily="Arial" fontSize="16" fill="#94a3b8" textAnchor="middle">Showing IAAII's 7 regional divisions</text>
                  </svg>
                </div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white opacity-30"></div>
              </div>
              <div className="w-full md:w-1/4 p-6 bg-[#182C49] text-white">
                <h3 className="text-xl font-bold mb-4">Global Presence</h3>
                <ul className="space-y-3">
                  {regions.map(region => (
                    <li key={region.id} className="flex items-center">
                      <span className="text-2xl mr-3">{region.icon}</span>
                      <button 
                        className={`text-left hover:text-[#F47B20] transition ${activeRegion === region.id ? 'text-[#F47B20] font-medium' : ''}`}
                        onClick={() => {
                          setActiveRegion(region.id);
                          document.getElementById(region.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }}
                      >
                        {region.name}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="mt-6 pt-6 border-t border-[#ffffff33]">
                  <p className="text-sm opacity-80">
                    Our organization spans 7 regions, encompassing more than 100 countries worldwide.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Region Cards */}
        <div className="space-y-12">
          {regions.map((region, index) => (
            <motion.div
              id={region.id}
              key={region.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + (index * 0.1), duration: 0.5 }}
              className={`bg-white rounded-xl shadow-lg overflow-hidden 
                          ${activeRegion === region.id ? 'ring-2 ring-offset-2' : ''}
                          transition-all duration-300`}
              style={{ 
                borderTop: `6px solid ${region.color}`,
                boxShadow: activeRegion === region.id ? `0 10px 25px -5px ${region.color}40` : ''
              }}
            >
              <div className="p-8">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                  <div className="flex items-center mb-4 md:mb-0">
                    <span className="text-4xl mr-4">{region.icon}</span>
                    <div>
                      <h2 className="text-2xl font-bold text-gray-900">{region.name}</h2>
                      <p className="text-sm text-gray-500">{region.code}</p>
                    </div>
                  </div>
                  <div className="text-sm text-gray-600 bg-gray-100 px-4 py-2 rounded-full">
                    {region.description}
                  </div>
                </div>

                <div className="border-t border-gray-100 pt-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Regional Representative</h3>
                  <div className="bg-gray-50 p-4 rounded-lg mb-6">
                    <p className="text-gray-700">{region.representative}</p>
                  </div>

                  <h3 className="text-lg font-medium text-gray-900 mb-3">
                    {region.subregions ? 'Subregions' : 'Countries'}
                  </h3>
                  
                  {region.subregions ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                      {region.subregions.map((subregion, idx) => (
                        <div key={idx} className="bg-gray-50 p-4 rounded-lg">
                          <h4 className="font-medium text-gray-900 mb-2 flex items-center">
                            <span 
                              className="inline-block w-3 h-3 rounded-full mr-2"
                              style={{ backgroundColor: region.color }}
                            ></span>
                            {subregion.name}
                            <span className="text-xs text-gray-500 ml-2">({subregion.code})</span>
                          </h4>
                          <ul className="space-y-1 ml-5">
                            {subregion.countries.map((country, cidx) => (
                              <li key={cidx} className="text-gray-700 text-sm">
                                {country.name}
                                <span className="text-xs text-gray-500 ml-1">{country.code && `(${country.code})`}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                        {region.countries.map((country, cidx) => (
                          <li key={cidx} className="text-gray-700 flex items-center">
                            <span 
                              className="inline-block w-2 h-2 rounded-full mr-2"
                              style={{ backgroundColor: region.color }}
                            ></span>
                            {country.name}
                            <span className="text-xs text-gray-500 ml-1">{country.code && `(${country.code})`}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {region.additionalCountries && (
                    <div>
                      <h4 className="font-medium text-gray-900 mb-2">Additional Countries</h4>
                      <ul className="list-disc pl-5 space-y-1">
                        {region.additionalCountries.map((country, cidx) => (
                          <li key={cidx} className="text-gray-700">{country.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Regional Representatives' Responsibilities */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.5 }}
          className="mt-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8">
              <div className="flex items-center mb-6">
                <div className="h-12 w-12 rounded-full bg-[#F47B20] text-white flex items-center justify-center text-xl mr-4">
                  🤝
                </div>
                <h2 className="text-2xl font-bold text-gray-900">Regional Representatives' Responsibilities</h2>
              </div>
              
              <p className="text-gray-700 mb-6">
                Each Regional Representative within the IAAII Council plays a crucial role in connecting global initiatives with local implementation:
              </p>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {responsibilities.map((responsibility, index) => (
                  <div key={index} className="bg-gray-50 p-5 rounded-lg flex">
                    <div className="h-8 w-8 rounded-full bg-[#182C49] text-white flex-shrink-0 flex items-center justify-center mr-4">
                      {index + 1}
                    </div>
                    <p className="text-gray-700">{responsibility}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-[#182C49]/5 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-[#182C49] mb-2">Interested in Regional Leadership?</h3>
                <p className="text-gray-600">
                  IAAII members can nominate themselves or colleagues for regional representative positions. 
                  Elections are held every two years. Contact <span className="text-[#F47B20] font-medium">regions@iaaii.org</span> to learn more.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default RegionsPage; 