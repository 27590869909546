import React from 'react';
import { motion } from 'framer-motion';

const EditorialBoardPage = () => {
  const editors = [
    {
      name: "TBA",
      title: "Editor-in-Chief",
      affiliation: "",
      specialization: ""
    },
    {
      name: "TBA",
      title: "Associate Editor",
      affiliation: "",
      specialization: ""
    },
    {
      name: "TBA",
      title: "Associate Editor",
      affiliation: "",
      specialization: ""
    },
    {
      name: "TBA",
      title: "Editorial Board Member",
      affiliation: "",
      specialization: ""
    },
    {
      name: "TBA",
      title: "Editorial Board Member",
      affiliation: "",
      specialization: ""
    },
    {
      name: "TBA",
      title: "Editorial Board Member",
      affiliation: "",
      specialization: ""
    }
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Meet Our Editorial Team
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Our journal is supported by distinguished experts in AI and informatics
          </p>
        </motion.div>
        
        <div className="max-w-5xl mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {editors.map((editor, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * index, duration: 0.5 }}
              className="bg-white rounded-xl shadow-md overflow-hidden p-6"
            >
              <div className="h-16 w-16 rounded-full bg-[#182C49]/10 flex items-center justify-center mx-auto mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-[#182C49]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-center text-gray-900 mb-1">{editor.name}</h3>
              <p className="text-[#F47B20] text-center font-medium mb-2">{editor.title}</p>
            </motion.div>
          ))}
        </div>
        
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
          className="bg-[#182C49]/5 p-6 rounded-lg text-center mt-12 max-w-4xl mx-auto"
        >
          <p className="text-gray-700">
            Our editorial team is currently being assembled. Check back soon for updates.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default EditorialBoardPage; 