import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const IndividualMembershipPage = () => {
  const benefits = [
    "Global Networking: Connect with leading AI and informatics professionals, researchers, and policymakers from around the world.",
    "Exclusive Resources: Gain access to IAAII publications, white papers, datasets, and research tools.",
    "Professional Development: Attend webinars, workshops, and conferences at discounted rates to enhance your skills and knowledge.",
    "Career Opportunities: Explore job postings, collaborations, and research opportunities through IAAII's network.",
    "Influence AI Policy: Participate in working groups and task forces that shape ethical AI frameworks and global standards."
  ];

  const roles = [
    "Contributing Expertise: Share your knowledge and insights to advance AI and informatics research and practice.",
    "Participating in Initiatives: Join working groups, task forces, and regional events to drive IAAII's global agenda.",
    "Promoting IAAII: Share IAAII's resources, events, and opportunities within your professional and academic networks.",
    "Representing Diverse Perspectives: Ensure that the voices of professionals, researchers, and students are reflected in IAAII's initiatives."
  ];

  const applicationSteps = [
    "Complete the Application Form",
    "Select Your Membership Category: Choose the category that best fits your profile (Student, Professional, or Researcher)",
    "Submit Your CV/Resume: Highlight your experience and contributions to AI and informatics",
    "Await Confirmation: Our Membership Committee will review your application and notify you of your acceptance"
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Individual Membership
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Join IAAII as an Individual Member – Be Part of the Global AI and Informatics Community!
          </p>
          <p className="mt-4 max-w-3xl mx-auto text-lg text-gray-600">
            The International Association of Artificial Intelligence and Informatics (IAAII) invites professionals, researchers and students to join as individual members. Access exclusive resources, network with global experts and help shape the future of AI and Informatics.
          </p>
          
          <div className="mt-8">
            <Link to="/membership-application">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#F47B20] hover:bg-[#e06610] focus:outline-none"
              >
                Join Now
              </motion.button>
            </Link>
          </div>
        </motion.div>
        
        {/* Why Become an Individual Member? */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Why Become an Individual Member?
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Advance Your Career and Knowledge
              </h3>
              
              <div className="space-y-4 mt-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <p className="text-gray-700">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Role of Individual Members */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Role of Individual Members
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Your Role in IAAII
              </h3>
              <p className="text-gray-700 mb-4">
                Individual Members play a vital role in IAAII's mission by:
              </p>
              
              <div className="space-y-4 mt-6">
                {roles.map((role, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-[#182C49] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700">{role}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-gray-50 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Global Community
                </h4>
                <p className="text-gray-600">
                  Join a worldwide network of professionals dedicated to advancing AI and informatics across borders, disciplines, and industries.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* How to Apply */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                How to Apply
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Join the IAAII Community
              </h3>
              
              <div className="space-y-4 mt-6">
                {applicationSteps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-8 w-8 flex items-center justify-center rounded-full bg-[#F47B20] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700 pt-1">{step}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 text-center">
                <Link to="/membership-application">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md shadow-lg text-white bg-[#182C49] hover:bg-[#121f35] focus:outline-none"
                  >
                    Join Now
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </motion.button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default IndividualMembershipPage; 