import React from 'react';
import { motion } from 'framer-motion';

const AboutJournalPage = () => {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            About the Journal
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <h2 className="mt-6 text-2xl font-bold text-gray-800">
            International Journal of Artificial Intelligence and Informatics
          </h2>
          <p className="mt-3 max-w-3xl mx-auto text-xl text-gray-600">
            A Premier Academic Publication in AI and Informatics
          </p>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <div className="prose prose-lg max-w-none text-gray-600">
                <div className="mb-8">
                  <p className="mb-4">
                    The International Journal of Artificial Intelligence and Informatics is a leading peer-reviewed journal dedicated to the advancement of knowledge and research in artificial intelligence (AI) and informatics. The journal is supported by Wisconsin College and a distinguished group of experts from the International Association of Artificial Intelligence and Informatics (IAAAI) and serves as a platform for the dissemination of cutting-edge research, innovations, and interdisciplinary applications in the fields of AI and informatics.
                  </p>
                  <p className="mb-4">
                    The journal publishes high-quality articles, including original papers, reviews and case studies, covering diverse topics such as machine learning, natural language processing, data science, computer vision and AI ethics. It provides a forum for academics, researchers and professionals to share insights and foster collaboration between academic and industrial fields.
                  </p>
                  <p>
                    Focusing on both theoretical advances and practical applications, the International Journal of Artificial Intelligence and Informatics aims to promote innovation in the field of AI technologies while considering the ethical, social and societal implications of their use. It is supported by the valued expertise of Wisconsin College and the IAAAI, which contributes to its reputation as a leading journal in the field.
                  </p>
                </div>
                
                <div className="border-t border-gray-200 my-8"></div>
                
                <h3 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Scope</h3>
                <p>
                  The International Journal of Artificial Intelligence and Informatics (IJAAI) publishes original research, reviews, and case studies in the following areas:
                </p>
                <ul className="list-disc pl-6 mb-6">
                  <li><strong>Artificial Intelligence:</strong> Machine learning, deep learning, natural language processing, computer vision, robotics, and AI ethics.</li>
                  <li><strong>Informatics:</strong> Data science, health informatics, bioinformatics, business informatics, and social informatics.</li>
                  <li><strong>Applications:</strong> AI in healthcare, education, finance, climate science, smart cities, and more.</li>
                  <li><strong>Interdisciplinary Research:</strong> AI and informatics in collaboration with fields like neuroscience, ethics, and public policy.</li>
                </ul>
                
                <h3 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Aims</h3>
                <ul className="list-disc pl-6 mb-6">
                  <li>To provide a platform for cutting-edge research in AI and informatics.</li>
                  <li>To foster interdisciplinary collaboration and innovation.</li>
                  <li>To promote ethical and responsible AI practices globally.</li>
                  <li>To disseminate knowledge through open-access publishing.</li>
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutJournalPage;