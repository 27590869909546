import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const CorporateMembershipPage = () => {
  const benefits = [
    "Collaborate on Global Initiatives: Partner with academia, governments, and NGOs to co-develop AI solutions for global challenges.",
    "Exclusive Access to Talent: Engage with top researchers, students, and professionals through IAAII's global network.",
    "Thought Leadership: Showcase your organization's expertise by contributing to IAAII white papers, panels, and policy frameworks.",
    "Brand Visibility: Gain exposure through IAAII events, publications, and media partnerships as a leader in ethical AI.",
    "Early Adoption Opportunities: Access groundbreaking research, datasets, and tools to stay ahead in AI innovation."
  ];

  const roles = [
    "Driving Innovation: Co-sponsor research projects, hackathons, or challenges to solve industry-specific problems.",
    "Shaping Ethical Standards: Participate in task forces to develop global guidelines for responsible AI deployment.",
    "Bridging Industry and Academia: Mentor startups, offer internships, and collaborate on curriculum development.",
    "Advocating for Progress: Represent corporate interests in global AI policy discussions and standards development."
  ];

  const tiers = [
    {
      name: "Startup Membership",
      description: "For emerging AI companies (<50 employees).",
      benefits: "Networking opportunities, discounted event rates, and mentorship programs."
    },
    {
      name: "Enterprise Membership",
      description: "For established corporations (500+ employees).",
      benefits: "Priority access to research, co-branded initiatives, and global policy influence."
    },
    {
      name: "Non-Profit/Government Membership",
      description: "For NGOs, public institutions, and government bodies.",
      benefits: "Collaborative grants, policy advocacy platforms, and public-sector partnerships."
    }
  ];

  const applicationSteps = [
    "Complete the Application Form",
    "Submit Organizational Details: Provide company size, focus areas, and AI/informatics initiatives",
    "Await Review: Our Corporate Partnerships Team will contact you to finalize membership"
  ];

  const talentBenefits = [
    "Exclusive Talent Pool: Access resumes and profiles of IAAII's expert members (researchers, engineers, ethicists) for recruitment.",
    "Job Postings: Advertise AI and informatics roles directly to IAAII's global community of professionals and students.",
    "Recruitment Events: Participate in IAAII-hosted career fairs, hackathons, and networking sessions to connect with top talent."
  ];

  const consultationBenefits = [
    "Expert Advisory Services: Partner with IAAII's leading researchers and practitioners for tailored consultations on AI strategy, ethics, or technical challenges.",
    "Project Collaboration: Co-develop solutions with IAAII experts through sponsored research, pilot programs, or innovation challenges.",
    "Policy Guidance: Work with IAAII task forces to align your organization's AI practices with global ethical standards."
  ];

  const faqs = [
    {
      question: "How do we access IAAII's talent pool for hiring?",
      answer: "Corporate and Academic Members receive login credentials to IAAII's Expert Directory, where you can search profiles, post jobs, and schedule consultations."
    },
    {
      question: "Can we request consultations with specific experts (e.g., AI ethicists, data scientists)?",
      answer: "Yes! Submit a consultation request through our portal, and we'll match you with relevant experts based on your needs."
    }
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Job Posting Banner */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-[#182C49] to-[#2a4a7c] text-white p-4 rounded-lg text-center mb-8"
        >
          <p className="text-xl font-bold">Need AI Talent? Post Your Job Opening on IAAII's Career Board – Exclusive to Members!</p>
        </motion.div>
        
        {/* Header Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Corporate Institutional Membership
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Join IAAII as a Corporate Institutional Member – Lead Innovation in AI and Informatics!
          </p>
          <p className="mt-4 max-w-3xl mx-auto text-lg text-gray-600">
            The International Association of Artificial Intelligence and Informatics (IAAII) invites forward-thinking corporations and institutions to collaborate with global leaders in AI and informatics. Drive innovation, access cutting-edge research, and shape the future of ethical AI technologies.
          </p>
          
          <div className="mt-8">
            <Link to="/membership-application">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#F47B20] hover:bg-[#e06610] focus:outline-none"
              >
                Partner With Us
              </motion.button>
            </Link>
          </div>
        </motion.div>
        
        {/* Why Become a Corporate Institutional Member? */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Why Become a Corporate Institutional Member?
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Unlock Strategic Advantages
              </h3>
              
              <div className="space-y-4 mt-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <p className="text-gray-700">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Role of Corporate Institutional Members */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Role of Corporate Institutional Members
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Your Role in Shaping the Future of AI
              </h3>
              <p className="text-gray-700 mb-4">
                Corporate Institutional Members contribute to IAAII's mission by:
              </p>
              
              <div className="space-y-4 mt-6">
                {roles.map((role, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-[#182C49] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700">{role}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Membership Tiers */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Membership Tiers
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Tailored Membership Options
              </h3>
              
              <div className="grid gap-6 mt-6 md:grid-cols-3">
                {tiers.map((tier, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-6 border border-gray-200 hover:shadow-md transition-shadow duration-300">
                    <h4 className="text-lg font-bold text-[#182C49] mb-2">{tier.name}</h4>
                    <p className="text-sm text-gray-500 mb-4">{tier.description}</p>
                    <p className="text-gray-700">{tier.benefits}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 text-center">
                <Link to="/membership-application">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#182C49] hover:bg-[#121f35] focus:outline-none"
                  >
                    Explore Tiers
                  </motion.button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Talent & Expertise Access */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Talent & Expertise Access
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Tap into a Global Network of AI Experts
              </h3>
              
              <div className="mb-8">
                <h4 className="text-lg font-semibold text-[#182C49] mb-4">Hiring Benefits</h4>
                <div className="space-y-4">
                  {talentBenefits.map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <p className="text-gray-700">{benefit}</p>
                    </div>
                  ))}
                </div>
              </div>
              
              <div>
                <h4 className="text-lg font-semibold text-[#182C49] mb-4">Consultation Benefits</h4>
                <div className="space-y-4">
                  {consultationBenefits.map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <p className="text-gray-700">{benefit}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* How to Apply */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                How to Apply
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Join the IAAII Corporate Network
              </h3>
              
              <div className="space-y-4 mt-6">
                {applicationSteps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-8 w-8 flex items-center justify-center rounded-full bg-[#F47B20] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700 pt-1">{step}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 text-center">
                <Link to="/membership-application">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md shadow-lg text-white bg-[#182C49] hover:bg-[#121f35] focus:outline-none"
                  >
                    Apply Now
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </motion.button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* FAQs */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Frequently Asked Questions
              </h2>
              
              <div className="space-y-6 mt-6">
                {faqs.map((faq, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4 last:border-0 last:pb-0">
                    <h4 className="text-lg font-medium text-gray-900 mb-2">
                      {faq.question}
                    </h4>
                    <p className="text-gray-700">
                      {faq.answer}
                    </p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-[#182C49]/5 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Ready to Partner with IAAII?
                </h4>
                <p className="text-gray-600">
                  Contact our Corporate Relations team at <span className="text-[#F47B20] font-medium">corporate@iaaii.org</span>
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CorporateMembershipPage; 