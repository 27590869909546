import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const StudentMembershipPage = () => {
  // Calculate days remaining until May 1, 2025
  const calculateDaysRemaining = () => {
    const targetDate = new Date('2025-05-01');
    const currentDate = new Date();
    const timeDifference = targetDate - currentDate;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };

  const daysRemaining = calculateDaysRemaining();
  
  const benefits = [
    "Free Access Until 2025: Enjoy full Student Membership benefits at no cost until May 1, 2025.",
    "Exclusive Resources: Access IAAII publications, datasets, and research tools to boost your studies.",
    "Mentorship Programs: Connect with AI professionals for career guidance.",
    "Discounted Events: Attend conferences and workshops at student rates even after the free period ends."
  ];

  const applicationSteps = [
    "Complete the Application Form",
    "Submit Proof of Enrollment: Upload your student ID or enrollment letter."
  ];

  const faqs = [
    {
      question: "Is the free membership really free?",
      answer: "Yes! There are no hidden fees for Student Members until May 1, 2025."
    },
    {
      question: "What happens after May 1, 2025?",
      answer: "Your membership will automatically renew unless you cancel."
    }
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Limited-Time Banner */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-[#F47B20] to-[#FFB347] text-white p-4 rounded-lg text-center mb-8"
        >
          <p className="text-xl font-bold">🎉 Free Student Membership Until May 1, 2025 – Enroll Now!</p>
        </motion.div>
        
        {/* Header Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Student Membership
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Launch Your Career in AI and Informatics – Zero Cost for Students!
          </p>
          <p className="mt-4 max-w-3xl mx-auto text-lg text-gray-600">
            The International Association of Artificial Intelligence and Informatics (IAAII) invites students worldwide to join as Student Members at no cost until May 1, 2025. Access exclusive resources, mentorship, and global networking opportunities to kickstart your future in AI and informatics.
          </p>
          
          <div className="mt-8">
            <Link to="/membership-application">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#F47B20] hover:bg-[#e06610] focus:outline-none"
              >
                Claim Free Membership
              </motion.button>
            </Link>
          </div>
        </motion.div>
        
        {/* Why Join Now? */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Why Join Now?
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Unlock Free Benefits for a Limited Time
              </h3>
              
              <div className="space-y-4 mt-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <p className="text-gray-700">{benefit}</p>
                  </div>
                ))}
              </div>
              
              {/* Countdown Timer */}
              <div className="mt-8 bg-gray-50 rounded-lg p-6 text-center">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Limited Time Offer
                </h4>
                <div className="flex flex-col items-center">
                  <div className="text-5xl font-bold text-[#F47B20]">{daysRemaining}</div>
                  <div className="text-gray-600 mt-1">days remaining</div>
                </div>
                <p className="text-gray-600 mt-3">
                  Don't miss this opportunity to join IAAII at no cost!
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* How to Apply */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                How to Apply
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Claim Your Free Membership in 2 Steps
              </h3>
              
              <div className="space-y-4 mt-6">
                {applicationSteps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-8 w-8 flex items-center justify-center rounded-full bg-[#F47B20] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700 pt-1">{step}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 text-center">
                <Link to="/membership-application">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md shadow-lg text-white bg-[#182C49] hover:bg-[#121f35] focus:outline-none"
                  >
                    Apply Now
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </motion.button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* FAQs */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Frequently Asked Questions
              </h2>
              
              <div className="space-y-6 mt-6">
                {faqs.map((faq, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4 last:border-0 last:pb-0">
                    <h4 className="text-lg font-medium text-gray-900 mb-2">
                      {faq.question}
                    </h4>
                    <p className="text-gray-700">
                      {faq.answer}
                    </p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-[#182C49]/5 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Still Have Questions?
                </h4>
                <p className="text-gray-600">
                  Contact our Student Membership team at <span className="text-[#F47B20] font-medium">students@iaaii.org</span>
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default StudentMembershipPage; 