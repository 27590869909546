import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const AcademicMembershipPage = () => {
  const benefits = [
    "Global Collaboration: Partner with leading academic and research institutions worldwide to advance AI and informatics.",
    "Access to Resources: Gain exclusive access to IAAII publications, datasets, and research tools to support your faculty and students.",
    "Networking Opportunities: Connect with industry leaders, policymakers, and researchers through IAAII events and working groups.",
    "Influence AI Education: Contribute to the development of global AI and informatics curricula and certification programs.",
    "Recognition: Showcase your institution's commitment to AI innovation through IAAII's global platform."
  ];

  const roles = [
    "Promoting Research Excellence: Share your institution's research findings and contribute to IAAII's global knowledge base.",
    "Supporting Education: Collaborate on developing AI and informatics curricula, training programs, and certification standards.",
    "Facilitating Partnerships: Build bridges between academia, industry, and government to drive innovation and policy development.",
    "Representing Academic Interests: Ensure that the perspectives and needs of academic institutions are reflected in IAAII's global initiatives."
  ];

  const hiringBenefits = [
    "Faculty Recruitment: Post academic positions (professors, researchers) to IAAII's network of global AI experts.",
    "Student Placements: Connect your students with internships and job opportunities at IAAII's corporate and institutional partners."
  ];

  const consultationBenefits = [
    "Curriculum Development: Collaborate with IAAII experts to design cutting-edge AI and informatics programs.",
    "Research Partnerships: Engage IAAII members as visiting faculty, guest lecturers, or co-investigators on grants and projects.",
    "Ethics Review Panels: Request guidance from IAAII's Ethics Committee to ensure responsible AI research practices."
  ];

  const applicationSteps = [
    "Complete the Application Form",
    "Submit Institutional Details: Provide information about your institution's AI and informatics programs, research focus, and achievements",
    "Await Confirmation: Our Membership Committee will review your application and notify you of your acceptance"
  ];

  const faqs = [
    {
      question: "Who can become an Academic Institutional Member?",
      answer: "Universities, colleges, and research institutions with active AI and informatics programs are eligible to apply."
    },
    {
      question: "Is there a fee to join as an Academic Institutional Member?",
      answer: "Membership fees are based on the size and type of institution. Please contact us for detailed information."
    },
    {
      question: "What are the benefits for students and faculty?",
      answer: "Students and faculty gain access to IAAII resources, events, and networking opportunities, as well as discounts on conferences and certifications."
    },
    {
      question: "How do we access IAAII's talent pool for hiring?",
      answer: "Corporate and Academic Members receive login credentials to IAAII's Expert Directory, where you can search profiles, post jobs, and schedule consultations."
    },
    {
      question: "Can we request consultations with specific experts (e.g., AI ethicists, data scientists)?",
      answer: "Yes! Submit a consultation request through our portal, and we'll match you with relevant experts based on your needs."
    }
  ];

  const tiers = [
    {
      name: "Standard",
      price: "$1,200",
      description: "For small to medium-sized academic institutions",
      features: [
        "5 designated faculty representatives",
        "Discounted memberships for additional faculty (15%)",
        "Institutional profile on IAAII website",
        "Access to educational resources and curriculum materials"
      ]
    },
    {
      name: "Premium",
      price: "$3,000",
      description: "For large universities and research institutions",
      features: [
        "15 designated faculty representatives",
        "Discounted memberships for additional faculty (25%)",
        "Enhanced institutional profile on IAAII website",
        "Priority access to educational resources",
        "Invitation to annual academic roundtable",
        "Collaboration opportunities with corporate members"
      ]
    }
  ];

  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Visiting Faculty Banner */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-[#182C49] to-[#2a4a7c] text-white p-4 rounded-lg text-center mb-8"
        >
          <p className="text-xl font-bold">Host an IAAII Expert at Your Institution – Explore Visiting Faculty Programs!</p>
        </motion.div>
        
        {/* Header Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Academic Institutional Membership
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-gray-600">
            Join IAAII as an Academic Institutional Member – Advance AI and Informatics Education and Research!
          </p>
          <p className="mt-4 max-w-3xl mx-auto text-lg text-gray-600">
            The International Association of Artificial Intelligence and Informatics (IAAII) invites universities, colleges, and research institutions to join as Academic Institutional Members. Collaborate with global leaders, access cutting-edge resources, and contribute to shaping the future of AI and informatics.
          </p>
          
          <div className="mt-8">
            <Link to="/membership-application">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#F47B20] hover:bg-[#e06610] focus:outline-none"
              >
                Apply Now
              </motion.button>
            </Link>
          </div>
        </motion.div>
        
        {/* Why Become an Academic Institutional Member? */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Why Become an Academic Institutional Member?
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Empower Your Institution
              </h3>
              
              <div className="space-y-4 mt-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <p className="text-gray-700">{benefit}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-[#182C49]/5 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Enhance Your Institution's Global Impact
                </h4>
                <p className="text-gray-600">
                  IAAII membership positions your institution at the forefront of AI and informatics innovation, providing a platform to showcase research and attract top talent.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Role of Academic Institutional Members */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Role of Academic Institutional Members
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Your Role in IAAII
              </h3>
              <p className="text-gray-600 mb-6">
                Academic Institutional Members play a vital role in IAAII's mission by:
              </p>
              
              <div className="space-y-4 mt-6">
                {roles.map((role, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-[#182C49] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700">{role}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-gray-50 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Global Academic Network
                </h4>
                <p className="text-gray-600">
                  Join a worldwide community of research institutions committed to advancing the field of AI and informatics through collaboration and knowledge sharing.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Talent & Knowledge Exchange */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Talent & Knowledge Exchange
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Leverage Expertise for Growth
              </h3>
              
              <div className="mb-8">
                <h4 className="text-lg font-semibold text-[#182C49] mb-4">Hiring Benefits</h4>
                <div className="space-y-4">
                  {hiringBenefits.map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <p className="text-gray-700">{benefit}</p>
                    </div>
                  ))}
                </div>
              </div>
              
              <div>
                <h4 className="text-lg font-semibold text-[#182C49] mb-4">Consultation Benefits</h4>
                <div className="space-y-4">
                  {consultationBenefits.map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <svg className="h-6 w-6 text-[#F47B20] mr-3 flex-shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <p className="text-gray-700">{benefit}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* How to Apply */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                How to Apply
              </h2>
              <h3 className="text-xl font-medium text-gray-900 mb-4">
                Join the IAAII Community
              </h3>
              
              <div className="space-y-4 mt-6">
                {applicationSteps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0 h-8 w-8 flex items-center justify-center rounded-full bg-[#F47B20] text-white mr-3 mt-0.5">
                      <span className="text-sm font-semibold">{index + 1}</span>
                    </div>
                    <p className="text-gray-700 pt-1">{step}</p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 text-center">
                <Link to="/membership-application">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md shadow-lg text-white bg-[#182C49] hover:bg-[#121f35] focus:outline-none"
                  >
                    Apply Now
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </motion.button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* FAQs */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="p-8 sm:p-12">
              <h2 className="text-3xl font-bold text-[#182C49] mb-6">
                Frequently Asked Questions
              </h2>
              
              <div className="space-y-6 mt-6">
                {faqs.map((faq, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4 last:border-0 last:pb-0">
                    <h4 className="text-lg font-medium text-gray-900 mb-2">
                      {faq.question}
                    </h4>
                    <p className="text-gray-700">
                      {faq.answer}
                    </p>
                  </div>
                ))}
              </div>
              
              <div className="mt-8 bg-[#182C49]/5 rounded-lg p-6">
                <h4 className="text-lg font-semibold text-[#182C49] mb-2">
                  Ready to Join? Apply Now and Become Part of a Global Movement in AI and Informatics!
                </h4>
                <p className="text-gray-600 mb-4">
                  For questions, contact us at <span className="text-[#F47B20] font-medium">academic@iaaii.org</span>
                </p>
                <div className="text-center mt-4">
                  <Link to="/membership-application">
                    <motion.button 
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#F47B20] hover:bg-[#e06610] focus:outline-none"
                    >
                      Apply Now
                    </motion.button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        

      </div>
    </div>
  );
};

export default AcademicMembershipPage; 