import React from 'react';
import { motion } from 'framer-motion';

const DisclaimerPage = () => {
  return (
    <div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Disclaimer
          </h1>
          <div className="mt-6 flex justify-center">
            <div className="h-1 w-24 bg-[#F47B20] rounded-full"></div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-8 sm:p-12">
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                The following information explains the purpose of the IAAII website, its privacy policy and other related materials. This information is provided to ensure transparency and compliance with best practices in the areas of privacy, ethical standards and website governance in accordance with the International Association of Artificial Intelligence and Informatics (IAAII) Code of Ethics.
              </p>
              <p>
                If you have any questions or concerns that IAAII is not adhering to the stated privacy policy, website practices or other aspects of best practice, you can contact us by email at <span className="text-[#F47B20] font-medium">iaai[at]iaai-global.org</span> or via the postal address.
              </p>
            </div>
          </div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="max-w-4xl mx-auto mt-12"
        >
          <div className="bg-[#182C49]/5 rounded-lg p-6">
            <h2 className="text-lg font-semibold text-[#182C49] mb-2">Contact Information</h2>
            <address className="not-italic text-gray-600">
              <p>International Association of Artificial Intelligence and Informatics</p>
              <p>IAAII Headquarters</p>
              <p>455 Wisconsin Avenue NW</p>
              <p>Washington, DC 20910</p>
              <p>United States</p>
              <p className="mt-2">
                Email: <span className="text-[#F47B20] font-medium">info@iaaii.org</span>
              </p>
            </address>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DisclaimerPage; 